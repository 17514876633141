import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import { compose } from "redux";
import { getLogin, getToken, getUrl, resetLogin, changeView } from "src/store";

// Boostrap & MD & Others
import { Nav } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "react-dropdown/style.css";
import "react-modern-drawer/dist/index.css";
import { MdArrowForwardIos } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { RxExit } from "react-icons/rx";

// Styled
import {
  StyledBtn, StyledTitle, StyledBtnMenu, StyledDrawerMenu, StyledHeader, CircleIcon
} from "./styled";

// Assets
import sticker from "src/assets/img/chequeabot-sticker.png";

// Logos
import QschLogo from "src/components/Qsch/Logo";
import TextosLogo from "src/components/Textos/Logo";
import DesgrabadorLogo from "src/components/Desgrabador/Logo";
import MonitoreoLogo from "src/components/Monitoreo/Logo";
import PushLogo from "src/components/Push/Logo";

const Header = (props) => {
  const history = useHistory();
  const [active, setActive] = useState(history.location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  useEffect(() => {
    setActive(history.location.pathname);
  }, [history.location.pathname]);

  const someFunct = (name) => {
    setActive(name);
  };


  const cleanLocalStorage = async () => {
    try {
      await localStorage.removeItem("token");
      await localStorage.removeItem("user");
      await localStorage.removeItem("items");
      props.getToken(null);
      props.getLogin(false);
      props.getUrl("");
      props.resetLogin();
    } catch (error) {
      console.error("Error al limpiar el almacenamiento local:", error);
    }
  };


  const handleMenu = () => {
    const menu = localStorage.getItem("items");
    const items = JSON.parse(menu);
    if (items) {
      const keysArray = Object.keys(items);
      const sortedItems = keysArray.sort((a, b) => items[a] - items[b]);
      return sortedItems.map((item) => {
        switch (item) {
          case "quesechequeahoy":
            return (
              <Nav key={item}>
                {active === "/qsch" ? (
                  <StyledBtnMenu
                    size="lg"
                    value="qsch"
                    name="/qsch"
                    className="active"
                    onClick={() => someFunct("/qsch")}
                  >
                    <Link to="/qsch">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <QschLogo />
                          <p style={{ marginBottom: "0" }}>
                            ¿Que se chequea hoy?
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                ) : (
                  <StyledBtnMenu
                    size="lg"
                    value="qsch"
                    name="/qsch"
                    onClick={() => someFunct("/qsch")}
                  >
                    <Link to="/qsch">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <QschLogo />
                          <p style={{ marginBottom: "0" }}>
                            ¿Que se chequea hoy?
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                )}
              </Nav>
            );
            break;
          case "desgrabador":
            return (
              <Nav key={item}>
                {active === "/desgrabador" ? (
                  <StyledBtnMenu
                    size="lg"
                    value="desgrabador"
                    name="/desgrabador"
                    className="active"
                    onClick={() => someFunct("/desgrabador")}
                  >
                    <Link to="/desgrabador">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <DesgrabadorLogo />
                          <p style={{ marginBottom: "0" }}>Desgrabador</p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                ) : (
                  <StyledBtnMenu
                    size="lg"
                    value="desgrabador"
                    name="/desgrabador"
                    onClick={() => someFunct("/desgrabador")}
                  >
                    <Link to="/desgrabador">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <DesgrabadorLogo />
                          <p style={{ marginBottom: "0" }}>Desgrabador</p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                )}
              </Nav>
            );
          case "monitoreo":
            return (
              <Nav key={item}>
                {active === "/periscopio" ? (
                  <StyledBtnMenu
                    size="lg"
                    value="periscopio"
                    name="/periscopio"
                    className="active"
                    onClick={() => someFunct("/periscopio")}
                  >
                    <Link to="/periscopio">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <MonitoreoLogo />
                          <p style={{ marginBottom: "0" }}>Periscopio</p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                ) : (
                  <StyledBtnMenu
                    size="lg"
                    value="periscopio"
                    name="/periscopio"
                    onClick={() => someFunct("/periscopio")}
                  >
                    <Link to="/periscopio">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <MonitoreoLogo />
                          <p style={{ marginBottom: "0" }}>Periscopio</p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                )}
              </Nav>
            );
          case "push":
            return (
              <Nav key={item}>
                {active === "/push" ? (
                  <StyledBtnMenu
                    size="lg"
                    value="push"
                    name="/push"
                    className="active"
                    onClick={() => someFunct("/home/push")}
                  >
                    <Link to="/push">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <PushLogo />
                          <p style={{ marginBottom: "0" }}>
                            Notificaciones push
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                ) : (
                  <StyledBtnMenu
                    size="lg"
                    value="push"
                    //name="/push"
                    onClick={() => someFunct("/home/push")}
                  >
                    <Link to="/push">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <PushLogo />
                          <p style={{ marginBottom: "0" }}>
                            Notificaciones push
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                )}
              </Nav>
            );
          case "analisisdetextos":
            return (
              <Nav key={item}>
                {active === "/textos" ? (
                  <StyledBtnMenu
                    size="lg"
                    value="textos"
                    name="/textos"
                    className="active"
                    onClick={() => someFunct("/textos")}
                  >
                    <Link to="/textos">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <TextosLogo />
                          <p style={{ marginBottom: "0" }}>
                            Análisis de textos
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                ) : (
                  <StyledBtnMenu
                    size="lg"
                    value="textos"
                    name="/textos"
                    onClick={() => someFunct("/textos")}
                  >
                    <Link to="/textos">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="texto-nav"
                        >
                          <TextosLogo />
                          <p style={{ marginBottom: "0" }}>
                            Análisis de textos
                          </p>
                        </div>
                        <MdArrowForwardIos className="arrow" />
                      </div>
                    </Link>
                  </StyledBtnMenu>
                )}
              </Nav>
            );
          case "ajustes":
            return (
              <div key={item} style={{marginTop: "-10px"}}>
                <hr className="hr-ajustes" />
                <Nav style={{marginTop: "-10px"}}>
                  {active === "/user" ? (
                    <StyledBtnMenu
                      size="lg"
                      value="user"
                      name="/user"
                      className="active"
                      onClick={() => someFunct("/user")}
                      style={{ margin: "1rem 0" }}
                    >
                      <Link to="/user">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ marginRight: "10px" }}
                            className="texto-nav"
                          >
                            <CircleIcon className="circle">
                              <IoMdSettings size={20} className="settings" />
                            </CircleIcon>
                            <p style={{ marginBottom: "0" }}>
                              Ajustes de usuario
                            </p>
                          </div>
                          <MdArrowForwardIos className="arrow" />
                        </div>
                      </Link>
                    </StyledBtnMenu>
                  ) : (
                    <StyledBtnMenu
                      size="lg"
                      value="user"
                      name="/user"
                      onClick={() => someFunct("/user")}
                      style={{ margin: "1rem 0" }}
                    >
                      <Link to="/user">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ marginRight: "10px" }}
                            className="texto-nav"
                          >
                            <CircleIcon className="circle">
                              <IoMdSettings size={20} className="settings" />
                            </CircleIcon>
                            <p style={{ marginBottom: "0" }}>
                              Ajustes de usuario
                            </p>
                          </div>

                          <MdArrowForwardIos className="arrow" />
                        </div>
                      </Link>
                    </StyledBtnMenu>
                  )}
                </Nav>
              </div>
            );
          default:
            return null;
            break;
        }
      });
    }


  }




  return (
    <StyledHeader
      id="header"
      isMenuOpen={isMenuOpen}
      onMouseEnter={() => setIsMenuOpen(true)}
      onMouseLeave={() => setIsMenuOpen(false)}
    >
      <StyledDrawerMenu>
        <div
          style={{
            paddingTop: "4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={sticker}
            className="d-block"
            alt=""
            style={{ width: "30px" }}
          />

          <StyledTitle isMenuOpen={isMenuOpen}>Chequeabot</StyledTitle>
        </div>
        <hr />

        <ul className="menu-content-open" id="myDropdown">
          <li>{handleMenu()}</li>
        </ul>
        {props.login ? (
          <Nav>
            <StyledBtnMenu
              size="lg"
              value="login"
              name="/login"
              onClick={() => cleanLocalStorage()}
            >
              <Link to="/login">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="exit texto-nav">
                    <RxExit
                      size={20}
                      style={{ marginRight: "16px", marginLeft: "4px" }}
                    />
                    <p style={{ marginBottom: "0" }}>Cerrar sesión</p>
                  </div>
                  <MdArrowForwardIos className="arrow" />
                </div>
              </Link>
            </StyledBtnMenu>
          </Nav>
        ) : (
          <StyledBtn size="lg">
            <Link to="/login"> Ingresar</Link>
          </StyledBtn>
        )}
      </StyledDrawerMenu>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    user: state.user,
    header: state.header,
    items_menu: state.items_menu,
    expand: state.expand,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (status) => dispatch(getLogin(status)),
    getToken: (token) => dispatch(getToken(token)),
    getUrl: (url) => dispatch(getUrl(url)),
    resetLogin: () => dispatch(resetLogin()),
    changeView: () => dispatch(changeView()),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
