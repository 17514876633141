import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import api from "./utils/api";
import { composeWithDevTools } from "redux-devtools-extension";

const INITIAL_STATE = {
  cards: {},
  filter: {},
  loading: false,
  error: false,
  login: false,
  token: null,
  url: "/",
  error_trello: false,
  textos: [],
  label: [],
  desgrabador: [],
  days: 3,
  media_source: "all",
  order: "-relevance_score",
  page: 1,
  search: null,
  category: null,
  loading_filter: false,
  loading_trello: false,
  trello: [],
  error_desgrabador: false,
  search_name: null,
  search_title: null,
  items_menu: [],
  messages: {},
  similares: {},
  user_data: [],
  filtros_app: {},
  new_pass: null,
  news_pass_status: false,
  new_data_user: null,
  new_data_user_success: false,
  error_new_data_user: false,
  list_users: {},
  list_users_delete_sucess: false,
  medios_user_qsch: {},
  medios_user_qsch_exist: false,
  code_new_user: {},
  code_new_user_status: "idle",
  first_password: {},
  first_password_status: "idle",
  reset_password: {},
  error_new_password: false,
  reset_password_complete: {},
  reset_password_complete_status: "idle",
  file_error: false,
  error_invitation: false,
  error_filter: false,
  error_monitoreo: false,
  redes_qsch: {},
  redes_qsch_exist: false,
  monitoreo_user: [],
  monitoreo_user_app_error: [],
  menu_usuarios: {},
  appministrador: [],
  appministrador_conversation: {},
  monitoreo_trello: false,
  appministrador_pedidos: {},
  messages_pedidos: {},
  delete_pedido: false,
  page_conversation: 1,
  appministrador_conversation_results: [],
  veredictos: [],
  menu_appministrador: {},
  status_rta: false,
  grafico: [],

  monitoreo: null,
  monitoreo_id: {},
  caregorias_form: [],
  trendings: [],
  cards_monitoreo: null,
  desinformaciones_detalle: [],
  nuevo_registro: null,
  btn_registro: false,
  registro_status: "idle",
  nueva_categoria: [],
  nueva_desinformaciones: [],
  filtros_feed: [],
  desinformaciones: [],
  registro_status_manual: "idle",
  page_registrados: [],
  id_registro: null,
  id_registro_data: [],

  // REFACTOR

  categorias_detalle: [],
  status_trello: null,
  nuevo_id: null,
  next: null,
  expand: false,
  data: "idle",
  campanas: [],
  label_desgrabador: [],

  // REFACTOR MONITOREO JOACO
  monitoreo__current_form_is_open: false,
  monitoreo__current_form_id: null,
  monitoreo__current_form_publication_id: null,
  // ID of the card where the new publication loaded is gonna be stored
  monitoreo__load_publication_card_selected_id: null,
  monitoreo__card_selected: {},
  monitoreo__events: [],
  monitoreo__topics: [],
  monitoreo__subtopics: {},
  monitoreo__new_event: null,
  desgrabador_historial: [],
  desgrabador_historial_item: [],

  desgrabador_historial_fichero: [],
  desgrabador_historial_fichero_item: [],
  desgrabador_error: false,
};

// REDUCER

function productsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FINISHED_LOADING": {
      return {
        ...state,
        loading: false,
      };
    }

    case "FETCH_CARDS_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        cards,
        loading: false,
      };
    }

    case "FETCH_FILTER_REQUEST": {
      return {
        ...state,
      };
    }

    case "FETCH_FILTER_SUCCESS": {
      const { filter } = action.payload;
      return {
        ...state,
        filter: filter,
      };
    }

    case "LOGIN": {
      const { status } = action.payload;
      return {
        ...state,
        login: status,
      };
    }

    case "RESET_STORE": {
      return INITIAL_STATE;
    }

    case "FETCH_TOKEN": {
      const { token } = action.payload;
      return {
        ...state,
        token,
      };
    }

    case "URL": {
      const { url } = action.payload;
      return {
        ...state,
        url: url,
      };
    }

    case "FILTER_FLAG": {
      return {
        ...state,
        loading_filter: true,
      };
    }

    case "FILTER_FLAG_SUCCESS": {
      return {
        ...state,
        loading_filter: false,
        error: false,
      };
    }

    case "FILTER_FLAG_ERROR": {
      return {
        ...state,
        error_filter: true,
      };
    }

    case "FILTER_FLAG_MONITOREO": {
      return {
        ...state,
        error_monitoreo: true,
      };
    }

    case "FETCH_DESGRABADOR": {
      const { data } = action.payload;
      return {
        ...state,
        desgrabador: data,
      };
    }

    case "SET_LABEL_DESGRABADOR": {
      const { data } = action.payload;

      const existingSubtitles = Array.isArray(state.desgrabador.subtitles)
        ? state.desgrabador.subtitles
        : [];

      const subtitlesArray = data.map((el, index) => {
        return {
          ...existingSubtitles[index],
          ...el,
        };
      });

      return {
        ...state,
        desgrabador: {
          ...state.desgrabador,
          subtitles: [...subtitlesArray],
        },
        label: [...subtitlesArray],
        error: false,
      };
    }

    case "SET_LABEL_TRELLO": {
      const { id } = action.payload;
      const newCards = state.cards.claims
        .filter((el) => el.id === id)
        .map((el, index) => {
          return { ...state.cards.claims[index], ...el };
        });
      return {
        ...state,
        trello: [...newCards],
        error: false,
      };
    }

    case "FETCH_TRELLO": {
      return {
        ...state,
        loading_trello: true,
      };
    }

    case "CHANGE_VIEW": {
      return {
        ...state,
        expand: !state.expand,
      };
    }

    case "FETCH_TRELLO_SUCCESS": {
      return {
        ...state,
        error_trello: false,
        loading_trello: false,
      };
    }

    case "FETCH_TRELLO_ERROR": {
      return {
        ...state,
        error_trello: true,
      };
    }

    case "FETCH_TEXTOS": {
      const { data } = action.payload;
      return {
        ...state,
        textos: data,
      };
    }

    case "FETCH_DAYS": {
      const { data } = action.payload;
      return {
        ...state,
        days: data,
      };
    }

    case "FETCH_QUERY": {
      const { data } = action.payload;
      return {
        ...state,
        search: data,
      };
    }

    case "FETCH_QUERY_NAME": {
      const { data } = action.payload;
      return {
        ...state,
        search_name: data,
      };
    }

    case "FETCH_QUERY_TITLE": {
      const { data } = action.payload;
      return {
        ...state,
        search_title: data,
      };
    }

    case "FETCH_PAGE": {
      const { data } = action.payload;
      return {
        ...state,
        page: data,
      };
    }

    case "FETCH_PAGE_CONVERSACION": {
      const { data } = action.payload;
      return {
        ...state,
        page_conversation: data,
      };
    }

    case "FETCH_MEDIA": {
      const { data } = action.payload;
      return {
        ...state,
        error: true,
        media_source: data,
      };
    }

    case "FETCH_ORDER": {
      const { data } = action.payload;
      return {
        ...state,
        error: true,
        order: data,
      };
    }

    case "FETCH_CATEGORY": {
      const { data } = action.payload;
      return {
        ...state,
        error: true,
        category: data,
      };
    }

    case "FETCH_CARD_STATUS": {
      return {
        ...state,
      };
    }

    case "FETCH_FAILURE": {
      return {
        ...state,
        error: true,
      };
    }

    case "FETCH_MESSAGES_APPMINISTRADOR_SUCCESS": {
      const { data } = action.payload;
      return {
        ...state,
        appministrador: data,
        loading: false,
      };
    }

    case "POST_MESSAGES_APPMINISTRADOR_REQUEST_VALIDATION": {
      const { data } = action.payload;
      return {
        ...state,
        appministrador: data,
        loading: false,
      };
    }

    case "FILTER_SIMILAR": {
      const { items } = action.payload;
      // const res = state.messages.filter((e) => items.includes(e));
      return {
        ...state,
        messages: items,
      };
    }

    case "FETCH_ITEMS_MENU": {
      const { items } = action.payload;

      return {
        ...state,
        items_menu: items,
      };
    }

    case "FETCH_USER": {
      const { data } = action.payload;
      return {
        ...state,
        user_data: data,
      };
    }

    case "FETCH_FILTER_APP_SUCCESS": {
      const { filter } = action.payload;
      return {
        ...state,
        filtros_app: filter,
      };
    }

    case "FETCH_NEW_PASS": {
      const { pass } = action.payload;
      return {
        ...state,
        new_pass: pass,
        new_pass_status: true,
      };
    }

    case "FETCH_NEW_USER_DATA": {
      const { data } = action.payload;
      return {
        ...state,
        new_data_user: data,
        new_data_user_success: true,
      };
    }

    case "FETCH_LIST": {
      const { data } = action.payload;
      return {
        ...state,
        list_users: data,
      };
    }

    case "FETCH_INVITATION": {
      return {
        ...state,
        list_users_post_status: true,
      };
    }

    case "FETCH_DELETE_INVITATION": {
      return {
        ...state,
        list_users_delete_sucess: true,
      };
    }

    case "FETCH_DELETE_PEDIDO": {
      const { data } = action.payload;
      return {
        ...state,
        delete_pedido: data,
        loading: false,
      };
    }

    case "FETCH_MEDIOS_USER_QSCH": {
      const { data } = action.payload;
      return {
        ...state,
        medios_user_qsch: data,
        medios_user_qsch_exist: true,
      };
    }

    case "FETCH_CODE_NEW_USER": {
      const { data } = action.payload;
      return {
        ...state,
        code_new_user: data,
        code_new_user_status: "success",
      };
    }

    case "FETCH_CODE_NEW_USER_FAIL": {
      return {
        ...state,
        code_new_user_status: "error",
      };
    }

    case "FETCH_FIRST_PASS": {
      const { data } = action.payload;
      return {
        ...state,
        first_password: data,
        first_password_status: "success",
      };
    }

    case "FETCH_FIRST_PASS_FAILURE": {
      return {
        ...state,
        first_password_status: "error",
      };
    }

    case "ERROR_NEW_PASSWORD": {
      const { status } = action.payload;
      return {
        ...state,
        error_new_password: status,
      };
    }

    case "ERROR_INVITATION": {
      const { status } = action.payload;
      return {
        ...state,
        error_invitation: status,
      };
    }

    case "ERROR_FILE": {
      const { status } = action.payload;
      return {
        ...state,
        file_error: status,
      };
    }

    case "ERROR_NEW_DATA": {
      const { status } = action.payload;
      return {
        ...state,
        error_new_data_user: status,
      };
    }

    case "RESET_PASSWORD_COMPLETE": {
      const { data } = action.payload;
      return {
        ...state,
        reset_password_complete: data,
        reset_password_complete_status: "success",
      };
    }

    case "RESET_PASSWORD_COMPLETE_FAILURE": {
      return {
        ...state,
        reset_password_complete_status: "error",
      };
    }

    case "FETCH_FILE_SUCCESS": {
      return {
        ...state,
        file: true,
        loading: false,
      };
    }

    case "FETCH_REDES_SUCCESS": {
      const { redes } = action.payload;
      return {
        ...state,
        redes_qsch: redes,
        loading: false,
        redes_qsch_exist: true,
      };
    }

    case "FETCH_MONITOREO_USER_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        monitoreo_user: cards,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_USER_APP_ERROR": {
      const { items } = action.payload;
      return {
        ...state,
        monitoreo_user_app_error: items,
      };
    }

    case "FETCH_MENU_USUARIOS_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        menu_usuarios: items,
        loading: false,
      };
    }

    case "FETCH_TRELLO_MONITOREO_REQUEST": {
      return {
        ...state,
        monitoreo_trello: false,
        loading: true,
      };
    }

    case "FETCH_TRELLO_MONITOREO_SUCCESS": {
      return {
        ...state,
        monitoreo_trello: true,
        loading: false,
      };
    }

    case "FETCH_PEDIDOS_REQUEST": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_PEDIDOS_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        appministrador_pedidos: cards,
        loading: false,
      };
    }

    case "FETCH_MESSAGES_APPMINISTRADOR_PEDIDOS_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        messages_pedidos: cards,
        loading: false,
      };
    }

    case "POST_RESPONSE_SUCCESS": {
      return {
        ...state,
        loading: false,
        status_rta: false,
      };
    }

    case "POST_RESPONSE_FAILURE": {
      return {
        ...state,
        loading: false,
        status_rta: true,
      };
    }

    case "FETCH_SIMILARES_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        similares: cards,
        loading: false,
      };
    }

    case "FETCH_VEREDICTOS_SUCCESS": {
      const { veredictos } = action.payload;
      return {
        ...state,
        veredictos,
        loading: false,
      };
    }

    case "FETCH_MENU_APP_SUCCESS": {
      const { menu } = action.payload;
      return {
        ...state,
        menu_appministrador: menu,
        loading: false,
      };
    }

    case "FETCH_GRAFICO_SUCCESS": {
      const { grafico } = action.payload;
      return {
        ...state,
        grafico,
        loading: false,
      };
    }

    case "FETCH_FILTER_APPMINISTRADOR": {
      const { filter_app } = action.payload;
      return {
        ...state,
        filter_app: filter_app,
      };
    }

    // NUEVO MONITOREO reducer

    case "FETCH_MONITOREO_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        monitoreo: items,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_TRENDINGS": {
      const { items } = action.payload;
      return {
        ...state,
        trendings: items,
        loading: false,
      };
    }

    case "FETCH_CATEGORIAS_DETALLE_SUCCESS": {
      const { categorias_detalle } = action.payload;
      return {
        ...state,
        categorias_detalle: categorias_detalle,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_ID": {
      const { items } = action.payload;
      return {
        ...state,
        monitoreo_id: items,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_CATEGORIAS_FORM": {
      const { items } = action.payload;
      return {
        ...state,
        categorias_form: items,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_CARDS": {
      const { items } = action.payload;
      return {
        ...state,
        cards_monitoreo: items,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_DESINFORMACIONES_DETALLE": {
      const { items } = action.payload;
      return {
        ...state,
        desinformaciones_detalle: items,
        loading: false,
      };
    }

    case "POST_MONITOREO_ASOCIADO_SUCCESS": {
      return {
        ...state,
        registro_status: "success",
      };
    }

    case "FETCH_MONITOREO_ASOCIADO_FAILURE": {
      return {
        ...state,
        registro_status: "error",
      };
    }

    case "RESET_REGISTRO_STATUS": {
      return {
        ...state,
        registro_status: "idle",
      };
    }

    case "FETCH_NUEVA_DESINFORMACION_SUCCESS": {
      const { cards } = action.payload;
      return {
        ...state,
        nueva_desinformaciones: cards,
        loading: false,
        registro_status_manual: "success",
      };
    }

    case "RESET_REGISTRO_STATUS_MANUAL_SUCCESS": {
      return {
        ...state,
        registro_status_manual: "success",
      };
    }

    case "RESET_REGISTRO_STATUS_MANUAL_ERROR": {
      return {
        ...state,
        registro_status_manual: "error",
      };
    }

    case "FETCH_MONITOREO_SUCCESS_DESINFORMACIONES_ID": {
      const { items } = action.payload;
      return {
        ...state,
        desinformaciones: items,
        loading: false,
      };
    }

    case "RESET_REGISTRO_STATUS_MANUAL": {
      return {
        ...state,
        registro_status_manual: "idle",
      };
    }

    case "UPDATE_MONITOREO_CARDS": {
      const { cards, index } = action.payload;
      const updatedMonitoreo = [...state.monitoreo];
      updatedMonitoreo[index].cards = cards;
      return {
        ...state,
        monitoreo: updatedMonitoreo,
      };
    }

    case "FETCH_REGISTRADOS_PAGE_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        page_registrados: items,
        loading: false,
      };
    }

    case "FETCH_MONITOREO_SUCCESS_NUEVO_ID": {
      const id = action.payload;
      return {
        ...state,
        nuevo_id: id,
        loading: false,
      };
    }

    case "FETCH_REGISTRO_ID_SUCCESS": {
      const { id } = action.payload;
      return {
        ...state,
        id_registro: id,
        loading: false,
      };
    }

    case "FETCH_REGISTRO_ID_DATA_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        id_registro_data: items,
        next: items.next,
        loading: false,
      };
    }

    case "FETCH_TRELLO_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        status_trello: items,
        loading: false,
      };
    }

    case "RESET_DESGRABADOR": {
      return {
        ...state,
        desgrabador: [],
        desgrabador_historial_item: [],
        desgrabador_historial_fichero_item: [],
        error: false,
      };
    }
    case "FETCH_DATA_SUCCESS": {
      const { status } = action.payload;
      return {
        ...state,
        data: status,
        loading: false,
      };
    }

    case "FETCH_DATA_CAMPANAS_SUCCESS": {
      const { items } = action.payload;
      return {
        ...state,
        campanas: items,
        loading: false,
      };
    }

    case "MONITOREO__TOGGLE_FORM": {
      const { openForm, form_id, publication_id } = action.payload;
      return {
        ...state,
        monitoreo__current_form_is_open: openForm,
        monitoreo__current_form_id: form_id,
        monitoreo__current_form_publication_id: publication_id,
      };
    }

    case "MONITOREO__SET_LOAD_PUBLICATION_CARD_SELECTED_ID": {
      const { cardSelectId } = action.payload;
      return {
        ...state,
        monitoreo__load_publication_card_selected_id: cardSelectId,
      };
    }

    case "MONITOREO__NEW_CARD_CREATED": {
      const { newCard } = action.payload;
      return {
        ...state,
        nuevo_registro: newCard.id,
      };
    }

    case "MONITOREO__FETCH_CARD_SELECTED": {
      const { items } = action.payload;
      return {
        ...state,
        monitoreo__card_selected: items,
      };
    }

    case "MONITOREO__FETCH_ALL_EVENTS": {
      const { items } = action.payload;

      items.forEach((event) => {
        event.label = event.name;
        event.value = event.id;
      });

      return {
        ...state,
        monitoreo__events: items,
      };
    }

    case "MONITOREO__FETCH_ALL_TOPICS": {
      const { items } = action.payload;

      var subtopics = items.reduce(function (map, obj) {
        map[obj.id] = obj.subtopics;
        return map;
      }, {});

      return {
        ...state,
        monitoreo__topics: items,
        monitoreo__subtopics: subtopics,
      };
    }

    case "MONITOREO__NEW_EVENT": {
      const { newEvent } = action.payload;

      // Update select fields
      newEvent.label = newEvent.name;
      newEvent.value = newEvent.id;

      return {
        ...state,
        monitoreo__new_event: newEvent,
        loading: false,
      };
    }

    case "FETCH_HITORIAL_DESGRABADOR": {
      const { items } = action.payload;
      return {
        ...state,
        desgrabador_historial: items,
      };
    }

    case "FETCH_HITORIAL_DESGRABADOR_ID": {
      const { items } = action.payload;
      return {
        ...state,
        desgrabador_historial_item: items,
      };
    }

    case "SET_LABEL_DESGRABADOR_HISTORIAL": {
      const { data } = action.payload;

      return {
        ...state,
        label_desgrabador: data,
        error: false,
      };
    }

    case "FETCH_HITORIAL_DESGRABADOR_FICHERO": {
      const { items } = action.payload;
      return {
        ...state,
        desgrabador_historial_fichero: items,
      };
    }

    case "FETCH_HITORIAL_DESGRABADOR_FICHERO_ID": {
      const { items } = action.payload;
      return {
        ...state,
        desgrabador_historial_fichero_item: items,
      };
    }

    case "FETCH_DESGRABADOR_ERROR": {
      return {
        ...state,
        desgrabador_error: true,
      };
    }

    case "FETCH_DESGRABADOR_ERROR_CLEAN": {
      return {
        ...state,
        desgrabador_error: false,
      };
    }

    case "CLEAR_LABEL_DESGRABADOR":
      return {
        ...state,
        label_desgrabador: [],
      };

    default: {
      return state;
    }
  }
}

// ACTIONS

const clearLabelDesgrabador = () => {
  return { type: "CLEAR_LABEL_DESGRABADOR" };
};

const loadingAction = () => {
  return { type: "LOADING" };
};

const finishedLoadingAction = () => {
  return { type: "FINSHED_LOADING" };
};

const fetchCardsSuccessAction = (cards) => {
  return { type: "FETCH_CARDS_SUCCESS", payload: { cards } };
};

const fetchFailureAction = () => {
  return { type: "FETCH_FAILURE" };
};

const fetchFilterSuccessAction = (filter) => {
  return { type: "FETCH_FILTER_SUCCESS", payload: { filter } };
};

const fetchToken = (token) => {
  return { type: "FETCH_TOKEN", payload: { token } };
};

const fetchUserLogin = (status) => {
  return { type: "LOGIN", payload: { status } };
};

const fetchResetLogin = () => {
  return { type: "RESET_STORE" };
};

const fetchResetDesgrabador = () => {
  return { type: "RESET_DESGRABADOR" };
};

const fetchUrl = (url) => {
  return { type: "URL", payload: { url } };
};

const fetchDesgrabador = (data) => {
  return { type: "FETCH_DESGRABADOR", payload: { data } };
};

const setLabelDesgrabador = (data) => {
  return { type: "SET_LABEL_DESGRABADOR", payload: { data } };
};

const setLabelDesgrabadorHistorial = (data) => {
  return { type: "SET_LABEL_DESGRABADOR_HISTORIAL", payload: { data } };
};

const fetchTextos = (data) => {
  return { type: "FETCH_TEXTOS", payload: { data } };
};

const fetchDays = (data) => {
  return { type: "FETCH_DAYS", payload: { data } };
};

const fetchQuery = (data) => {
  return { type: "FETCH_QUERY", payload: { data } };
};

const fetchQueryName = (data) => {
  return { type: "FETCH_QUERY_NAME", payload: { data } };
};

const fetchQueryTitle = (data) => {
  return { type: "FETCH_QUERY_TITLE", payload: { data } };
};

const fetchPage = (data) => {
  return { type: "FETCH_PAGE", payload: { data } };
};

const fetchMedia = (data) => {
  return { type: "FETCH_MEDIA", payload: { data } };
};

const fetchOrder = (data) => {
  return { type: "FETCH_ORDER", payload: { data } };
};

const fetchCategory = (data) => {
  return { type: "FETCH_CATEGORY", payload: { data } };
};

const fetchCardStatus = (value) => {
  return { type: "FETCH_CARD_STATUS", payload: { value } };
};

const fetchFilterFlag = () => {
  return { type: "FILTER_FLAG" };
};

const fetchFilterError = () => {
  return { type: "FILTER_FLAG_ERROR" };
};

const fetchMonitoreoError = () => {
  return { type: "FILTER_FLAG_MONITOREO" };
};

const fetchFilterFlagSuccess = () => {
  return { type: "FILTER_FLAG_SUCCESS" };
};

const fetchTrello = () => {
  return { type: "FETCH_TRELLO" };
};

const fetchTrelloSuccessAction = (id) => {
  return { type: "FETCH_TRELLO_SUCCESS", payload: { id } };
};

const fetchTrelloError = () => {
  return { type: "FETCH_TRELLO_ERROR" };
};

const setLabelTrello = (id) => {
  return { type: "SET_LABEL_TRELLO", payload: { id } };
};

const fetchItems = (items) => {
  return { type: "FETCH_ITEMS_MENU", payload: { items } };
};

const fetchMessagesAppministradorSuccessAction = (data) => {
  return { type: "FETCH_MESSAGES_APPMINISTRADOR_SUCCESS", payload: { data } };
};

const fetchMessagesFilter = (items) => {
  return { type: "FILTER_SIMILAR", payload: { items } };
};

const fetchMonitoreoRequestAction = () => {
  return { type: "FETCH_MONITOREO_REQUEST" };
};

const fetchMonitoreoSuccessAction = (items) => {
  return { type: "FETCH_MONITOREO_SUCCESS", payload: { items } };
};

const fetchTrelloMonitoreoRequestAction = () => {
  return { type: "FETCH_TRELLO_MONITOREO_REQUEST" };
};

const fetchTrelloMonitoreoSuccessAction = () => {
  return { type: "FETCH_TRELLO_MONITOREO_SUCCESS" };
};

const fetchUser = (data) => {
  return { type: "FETCH_USER", payload: { data } };
};

const fetchFilterAppSuccessAction = (filter) => {
  return { type: "FETCH_FILTER_APP_SUCCESS", payload: { filter } };
};

const fetchNewPass = (pass) => {
  return { type: "FETCH_NEW_PASS", payload: { pass } };
};

const fetchNewDataUser = (data) => {
  return { type: "FETCH_NEW_USER_DATA", payload: { data } };
};

const fetchListData = (data) => {
  return { type: "FETCH_LIST", payload: { data } };
};

const fetchListInvitation = (data) => {
  return { type: "FETCH_INVITATION", payload: { data } };
};

const fetchMediosUserQsch = (data) => {
  return { type: "FETCH_MEDIOS_USER_QSCH", payload: { data } };
};

const fetchCodeNewUser = (data) => {
  return { type: "FETCH_CODE_NEW_USER", payload: { data } };
};

const fetchFirstPass = (data) => {
  return { type: "FETCH_FIRST_PASS", payload: { data } };
};

const fetchFirstPassFailure = () => {
  return { type: "FETCH_FIRST_PASS_FAILURE" };
};

const fetchCodeNewUserFail = () => {
  return { type: "FETCH_CODE_NEW_USER_FAIL" };
};

const errorNewPassword = (status) => {
  return { type: "ERROR_NEW_PASSWORD", payload: { status } };
};

const errorNewData = (status) => {
  return { type: "ERROR_NEW_DATA", payload: { status } };
};

const errorInvitation = (status) => {
  return { type: "ERROR_INVITATION", payload: { status } };
};

const errorFile = (status) => {
  return { type: "ERROR_FILE", payload: { status } };
};

const fetchListInvitationDelete = (data) => {
  return { type: "FETCH_DELETE_INVITATION", payload: { data } };
};

const fetchListPedidoDelete = (data) => {
  return { type: "FETCH_DELETE_PEDIDO", payload: { data } };
};

const fetchLFileSuccess = () => {
  return { type: "FETCH_FILE_SUCCESS" };
};

const fetchRedesSuccessAction = (redes) => {
  return { type: "FETCH_REDES_SUCCESS", payload: { redes } };
};

const fetchMonitoreoUserSuccessAction = (cards) => {
  return { type: "FETCH_MONITOREO_USER_SUCCESS", payload: { cards } };
};

const fetchMonitoreoUserAppError = (items) => {
  return { type: "FETCH_MONITOREO_USER_APP_ERROR", payload: { items } };
};

const fetchMenuUsuariosSuccessAction = (items) => {
  return { type: "FETCH_MENU_USUARIOS_SUCCESS", payload: { items } };
};

const fetchHomePedidosRequestAction = () => {
  return { type: "FETCH_PEDIDOS_REQUEST" };
};

const fetchHomePedidosSuccessAction = (cards) => {
  return { type: "FETCH_PEDIDOS_SUCCESS", payload: { cards } };
};

const fetchMessagesAppministradorPedidosSuccessAction = (cards) => {
  return {
    type: "FETCH_MESSAGES_APPMINISTRADOR_PEDIDOS_SUCCESS",
    payload: { cards },
  };
};

const postResponseSuccessAction = (item) => {
  return { type: "POST_RESPONSE_SUCCESS", payload: { item } };
};

const postResponseErrorAction = (item) => {
  return { type: "POST_RESPONSE_FAILURE", payload: { item } };
};

const fetchSimilaresSuccessAction = (cards) => {
  return { type: "FETCH_SIMILARES_SUCCESS", payload: { cards } };
};

const fetchVeredictosRequestAction = () => {
  return { type: "FETCH_VEREDICTOS_REQUEST" };
};

const fetchVeredictosSuccessAction = (veredictos) => {
  return { type: "FETCH_VEREDICTOS_SUCCESS", payload: { veredictos } };
};

const fetchMenuAppSuccessAction = (menu) => {
  return { type: "FETCH_MENU_APP_SUCCESS", payload: { menu } };
};

const fetchGraficoSuccessAction = (grafico) => {
  return { type: "FETCH_GRAFICO_SUCCESS", payload: { grafico } };
};

// NUEVO MONITOREO actions
const fetchMonitoreoSuccessTrendingsAction = (items) => {
  return { type: "FETCH_MONITOREO_SUCCESS_TRENDINGS", payload: { items } };
};

const fetchCategoriasDetalleSuccess = (categorias_detalle) => {
  return {
    type: "FETCH_CATEGORIAS_DETALLE_SUCCESS",
    payload: { categorias_detalle },
  };
};

const fetchMonitoreoIDSuccessAction = (items) => {
  return { type: "FETCH_MONITOREO_SUCCESS_ID", payload: { items } };
};

const fetchMonitoreoSuccessCategorias = (items) => {
  return {
    type: "FETCH_MONITOREO_SUCCESS_CATEGORIAS_FORM",
    payload: { items },
  };
};

const fetchCardsMonitoreoSuccessAction = (items) => {
  return { type: "FETCH_MONITOREO_SUCCESS_CARDS", payload: { items } };
};

const fetchDetalleDesinformacionesSuccess = (items) => {
  return {
    type: "FETCH_MONITOREO_SUCCESS_DESINFORMACIONES_DETALLE",
    payload: { items },
  };
};

const fetchSearchMonitoreoRequestAction = () => {
  return { type: "FETCH_MONITOREO_REQUEST_SEARCH" };
};

const postMonitoreoAsociadoStatusSuccess = (data) => {
  return { type: "POST_MONITOREO_ASOCIADO_SUCCESS", payload: { data } };
};

const postMonitoreoAsociadoStatusFailure = () => {
  return { type: "FETCH_MONITOREO_ASOCIADO_FAILURE" };
};

const makeresetRegistroStatus = () => {
  return { type: "RESET_REGISTRO_STATUS" };
};

const showStatusRegistroManualSuccess = (data) => {
  return { type: "RESET_REGISTRO_STATUS_MANUAL_SUCCESS", payload: { data } };
};

const showStatusRegistroManualError = () => {
  return { type: "RESET_REGISTRO_STATUS_MANUAL_ERROR" };
};

const fetchMonitoreoSuccessDesinformacionesID = (items) => {
  return {
    type: "FETCH_MONITOREO_SUCCESS_DESINFORMACIONES_ID",
    payload: { items },
  };
};

const makeresetRegistroStatusManual = () => {
  return { type: "RESET_REGISTRO_STATUS_MANUAL" };
};

const updateMonitoreoCardsAction = (cards, index) => {
  return {
    type: "UPDATE_MONITOREO_CARDS",
    payload: { cards, index },
  };
};

const fetchGuardadosPageSuccessAction = (items) => {
  return { type: "FETCH_REGISTRADOS_PAGE_SUCCESS", payload: { items } };
};

const fetchMonitoreoNuevoIdSuccessAction = (id) => {
  return { type: "FETCH_MONITOREO_SUCCESS_NUEVO_ID", payload: id };
};

const fectchRegistroID = (id) => {
  return { type: "FETCH_REGISTRO_ID_SUCCESS", payload: { id } };
};

const fetchGuardadosIDdataSuccessAction = (items) => {
  return { type: "FETCH_REGISTRO_ID_DATA_SUCCESS", payload: { items } };
};

const changeExpand = () => {
  return { type: "CHANGE_VIEW" };
};

const fetchDataSuccessAction = (status) => {
  return { type: "FETCH_DATA_SUCCESS", payload: { status } };
};

const fetchDataSuccessCampanas = (items) => {
  return { type: "FETCH_DATA_CAMPANAS_SUCCESS", payload: { items } };
};

const monitoreoToggleForm = (openForm, form_id, publication_id) => {
  return {
    type: "MONITOREO__TOGGLE_FORM",
    payload: { openForm, form_id, publication_id },
  };
};

const monitoreoSetLoadPublicationCardSelectedID = (cardSelectId) => {
  return {
    type: "MONITOREO__SET_LOAD_PUBLICATION_CARD_SELECTED_ID",
    payload: { cardSelectId },
  };
};

const monitoreoCreateNewCardAction = (newCard) => {
  return { type: "MONITOREO__NEW_CARD_CREATED", payload: { newCard } };
};

const monitoreoCardSelected = (items) => {
  return { type: "MONITOREO__FETCH_CARD_SELECTED", payload: { items } };
};

const monitoreoFetchAllEvents = (items) => {
  return { type: "MONITOREO__FETCH_ALL_EVENTS", payload: { items } };
};

const monitoreoFetchAllTopics = (items) => {
  return { type: "MONITOREO__FETCH_ALL_TOPICS", payload: { items } };
};

const monitoreoNewEventAction = (newEvent) => {
  return { type: "MONITOREO__NEW_EVENT", payload: { newEvent } };
};

const fetchDesgrabadorHistorial = (items) => {
  return { type: "FETCH_HITORIAL_DESGRABADOR", payload: { items } };
};

const fetchDesgrabadorHistorialID = (items) => {
  return { type: "FETCH_HITORIAL_DESGRABADOR_ID", payload: { items } };
};

const fetchDesgrabadorHistorialFichero = (items) => {
  return { type: "FETCH_HITORIAL_DESGRABADOR_FICHERO", payload: { items } };
};

const fetchDesgrabadorHistorialFicheroID = (items) => {
  return { type: "FETCH_HITORIAL_DESGRABADOR_FICHERO_ID", payload: { items } };
};

const fetchDesgrabadorError = () => {
  return { type: "FETCH_DESGRABADOR_ERROR" };
};

const fetchDesgrabadorErrorClean = () => {
  return { type: "FETCH_DESGRABADOR_ERROR_CLEAN" };
};

// THUNK
const monitoreoCreateNewcard = (new_registro) => {
  return async function (dispatch) {
    try {
      const newCard = await api.fetchMonitoreoNuevoRegistro(new_registro);
      dispatch(monitoreoCreateNewCardAction(newCard));
    } catch (error) {
      // TODO: dispatch(fetchMonitoreoNuevoRegistroSuccessFailure());
      console.log(error);
    }
  };
};

const monitoreoCreateNewPublication = (newPublication) => {
  return async function (dispatch) {
    try {
      const newPublicationId = await api.postNuevaDesinformacionManual(
        newPublication
      );
      dispatch(postMonitoreoAsociadoStatusSuccess());
    } catch (error) {
      dispatch(showStatusRegistroManualError());
    }
  };
};

const monitoreoCreateNewPublicationTwitter = (newPublicationTwitter) => {
  return async function (dispatch) {
    try {
      const newPublicationId = await api.postNuevaDesinformacionManualTwitter(
        newPublicationTwitter
      );
      dispatch(postMonitoreoAsociadoStatusSuccess());
    } catch (error) {
      dispatch(showStatusRegistroManualError());
    }
  };
};

const getCardsRequestDefault = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const cards = await api.fetchDataCardsDefault(arg);
      dispatch(fetchCardsSuccessAction(cards));
      dispatch(fetchFilterFlagSuccess());
    } catch (error) {
      dispatch(fetchFilterError());
    }
  };
};

const getFilterRequest = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const filter = await api.fetchDataFilter();
      dispatch(fetchFilterSuccessAction(filter));

      dispatch(loadingAction());
      const cards = await api.fetchDataCardsDefault(arg);
      dispatch(fetchCardsSuccessAction(cards));
      dispatch(fetchFilterFlagSuccess());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getLogin = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchUserLogin(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};


const getCleanErrorDesgrabador = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchDesgrabadorErrorClean());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getToken = (token) => {
  return async function (dispatch) {
    try {
      dispatch(fetchToken(token));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getUrl = (url) => {
  return async function (dispatch) {
    try {
      dispatch(fetchUrl(url));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDesgrabador = (video_url, lang) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabador(video_url, lang);
      dispatch(getDesgrabadorHistorial());
      if (data) {
        dispatch(fetchDesgrabador(data));
        if (lang === "es" && data.subtitles && data.subtitles.length > 0) {
          const sentences = data.subtitles.map((el) => el.text);

          if (sentences && sentences.length > 0) {
            await dispatch(getTextosDesgrabador(sentences));
            console.log("getTextosDesgrabador llamado con éxito.");
          } else {
            console.log("No hay sentences válidas para getTextosDesgrabador.");
          }
        }
      } else {
        console.log("No hay datos de desgrabador en la respuesta.");
      }
    } catch (error) {
      console.error("Error en getDesgrabador:", error);
      dispatch(fetchDesgrabadorError(error));
    }
  };
};

const getDesgrabadorFile = (file) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabadorFile(file);

     
      dispatch(getDesgrabadorHistorialFichero()); // Agregar el archivo al historial
    } catch (error) {
       dispatch(fetchDesgrabadorError(error));
    }
  };
};

const getTextos = (text) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchTextos(text);
      dispatch(fetchTextos(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getTextosDesgrabador = (sentences) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchTextosDesgrabador(sentences);
      dispatch(setLabelDesgrabador(data));
    } catch (error) {
      dispatch(fetchDesgrabadorError(error));
    }
  };
};

const getTextosDesgrabadorHistorial = (sentences) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchTextosDesgrabador(sentences);
      dispatch(setLabelDesgrabadorHistorial(data));
    } catch (error) {
      dispatch(fetchDesgrabadorError(error));
    }
  };
};

const getDays = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchDays(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getQuery = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchQuery(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getQueryName = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchQueryName(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getPage = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchPage(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMedia = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchMedia(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getOrder = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchOrder(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getCategory = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchCategory(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getCardStatus = (id, ic) => {
  return async function (dispatch) {
    try {
      const value = await api.fetchDataCardStatus(id, ic);
      dispatch(getCardsRequestDefault());
      dispatch(fetchCardStatus());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getFilterFlag = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchFilterFlag());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getTrello = (id) => {
  return async function (dispatch) {
    try {
      dispatch(fetchTrello());
      const value = await api.fetchTrello(id);
      dispatch(setLabelTrello(id));
      dispatch(fetchTrelloSuccessAction());
    } catch (error) {
      dispatch(fetchTrelloError(error));
    }
  };
};

const getQueryTitle = (status) => {
  return async function (dispatch) {
    try {
      dispatch(fetchQueryTitle(status));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getItemsMenu = () => {
  return async function (dispatch) {
    try {
      const items = await api.fetchDataModulos();

      dispatch(fetchItems(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMessagesUpdate = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data1 = await api.fetchAppministradorUpdate();
      dispatch(finishedLoadingAction());

      dispatch(loadingAction());
      const data2 = await api.postAppministrador(data1);
      // TODO: implement this
      // dispatch(postMessagesAppministradorRequestValidation(data2));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const postResolveMessage = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data1 = await api.postResolveMessage(arg);
      dispatch(finishedLoadingAction());

      dispatch(loadingAction());
      const data2 = await api.fetchAppministrador();
      dispatch(fetchMessagesAppministradorSuccessAction(data2));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMessagesFilter = (items) => {
  return async function (dispatch) {
    try {
      dispatch(fetchMessagesFilter(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMonitoreoRequest = (filtros) => {
  return async function (dispatch) {
    try {
      dispatch(fetchMonitoreoRequestAction());
      const items = await api.fetchDataMonitoreo(filtros);
      dispatch(fetchMonitoreoSuccessAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getUserData = () => {
  return async function (dispatch) {
    try {
      const items = await api.fetchDataUser();
      dispatch(fetchUser(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDataCard = (id) => {
  return async function (dispatch) {
    try {
      if (!id) {
        dispatch(monitoreoCardSelected({}));
      } else {
        const items = await api.getMonitoreoCard(id);
        dispatch(monitoreoCardSelected(items));
      }
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getNewDataUser = (id, data) => {
  return async function (dispatch) {
    try {
      const pass = await api.fetchNewDataUser(id, data);
      dispatch(fetchNewDataUser(pass));
    } catch (error) {
      dispatch(errorNewData(true));
    }
  };
};

const getListData = () => {
  return async function (dispatch) {
    try {
      const data = await api.fetchListData();
      dispatch(fetchListData(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getListInvitation = (newMedio) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchListInvitation(newMedio);
      dispatch(fetchListInvitation(data));
      const reloadData = await api.fetchListData();
      dispatch(fetchListData(reloadData));
    } catch (error) {
      dispatch(errorInvitation(true));
    }
  };
};

const getNewPass = (items) => {
  return async function (dispatch) {
    try {
      const pass = await api.fetchDataResetPassword(items);
      dispatch(fetchNewPass(pass));
      localStorage.removeItem("token");
      localStorage.setItem("token", pass);
    } catch (error) {
      dispatch(errorNewPassword(true));
    }
  };
};

const getListInvitationDelete = (id) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchListInvitationDelete(id);
      dispatch(fetchListInvitationDelete(data));

      const reloadData = await api.fetchListData();
      dispatch(fetchListData(reloadData));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getPedidosDelete = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchPedidoDelete(arg);
      dispatch(fetchListPedidoDelete(data));

      const data1 = await api.fetchHomePedidos(arg);
      dispatch(fetchHomePedidosSuccessAction(data1));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMediosUserQsch = () => {
  return async function (dispatch) {
    try {
      const data = await api.fetchMediosUserQsch();
      dispatch(fetchMediosUserQsch(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getNewUserActive = (id, items) => {
  return async function (dispatch) {
    try {
      // const data = await api.fetchNewUserActive(id, items);
      // dispatch(fetchNewUserActive(data));
    } catch (error) {
      // dispatch(fetchFailureAction(error));
    }
  };
};

const getCodeNewUser = (userData) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchCodeNewUser(userData);
      dispatch(fetchCodeNewUser(data));
      localStorage.removeItem("token");
      localStorage.setItem("token", data.token);
    } catch (error) {
      dispatch(fetchCodeNewUserFail(error));
    }
  };
};

const getCodeNewUserFail = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchCodeNewUserFail());
    } catch (error) {
      dispatch(fetchFailureAction());
    }
  };
};

const getCodeFail = () => {
  /*   return async function (dispatch) {
    try {
      dispatch(fetchCodeNewUserFail());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  }; */
};

const getFirstPass = (newPass) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchFirstPass(newPass);
      dispatch(fetchFirstPass(data));
      localStorage.removeItem("token");
    } catch (error) {
      dispatch(fetchFirstPassFailure());
    }
  };
};

const getResetPassword = (email) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchResetPass(email);
      dispatch(fetchFirstPass(data));
    } catch (error) {
      dispatch(fetchFirstPassFailure());
    }
  };
};

const getResetPasswordComplete = (items) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchResetPasswordComplete(items);
      dispatch(fetchFirstPass(data));
    } catch (error) {
      dispatch(fetchFirstPassFailure());
    }
  };
};

const getFileUpload = (items) => {
  return async function (dispatch) {
    try {
      const data = await api.getFileUpload(items);
      dispatch(fetchLFileSuccess(data));

      dispatch(loadingAction());
      const redes = await api.fetchRedes();
      dispatch(fetchRedesSuccessAction(redes));
    } catch (error) {
      dispatch(errorFile(true));
    }
  };
};

const getRedesRequest = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const redes = await api.fetchRedes();
      dispatch(fetchRedesSuccessAction(redes));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMonitoreoUser = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataUserMonitoreo();
      dispatch(fetchMonitoreoUserSuccessAction(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const postMonitoreoUser = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.postDataUserMonitoreo(arg);
      dispatch(fetchMonitoreoUserAppError(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const resetLogin = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchResetLogin());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const resetDesgrabador = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchResetDesgrabador());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMenuUsuarios = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchMenuUsuarios();
      dispatch(fetchMenuUsuariosSuccessAction(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getTrelloMonitoreo = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(fetchTrelloMonitoreoRequestAction());
      const value = await api.fetchMonitoreoTrello(arg);
      dispatch(fetchTrelloMonitoreoSuccessAction());
      return value;
    } catch (error) {
      dispatch(fetchTrelloError(error));
    }
  };
};

const fetchHomePedidos = (arg) => {
  return async function (dispatch) {
    try {
      dispatch(fetchFilterAppSuccessAction(arg));

      dispatch(loadingAction());
      const data = await api.fetchHomePedidos(arg);
      dispatch(fetchHomePedidosSuccessAction(data));

      dispatch(loadingAction());
      const veredictos = await api.fetchVeredictos();
      dispatch(fetchVeredictosSuccessAction(veredictos));
      dispatch(
        fetchMenuAppSuccessAction({
          categories: [
            ["ME", "Mensajes", "/appministrador"],
            ["PE", "Pedidos", "/appministrador/pedidos"],
          ],
        })
      );

      dispatch(loadingAction());
      const grafico = await api.getGrafico(arg);
      dispatch(fetchGraficoSuccessAction(grafico));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const fetchMessagesPedidos = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchMessagesPedidos(id);
      dispatch(fetchMessagesAppministradorPedidosSuccessAction(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const postMessages = (items, data3) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.postMessages(items);
      dispatch(postResponseSuccessAction(data));
      const data1 = await api.fetchHomePedidos(data3);
      dispatch(fetchHomePedidosSuccessAction(data1));
    } catch (error) {
      dispatch(postResponseErrorAction());
    }
  };
};

const getSimilares = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.getSimilares(id);
      dispatch(fetchSimilaresSuccessAction(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMenuApp = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const menu = await api.getMenuApp();
      dispatch(fetchMenuAppSuccessAction(menu));
    } catch (error) {
      dispatch(fetchFilterError());
    }
  };
};

// NUEVO MONITREO funciones

const getMonitoreoTrendings = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoTrendings();
      dispatch(fetchMonitoreoSuccessTrendingsAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getMonitoreoAllEvents = () => {
  return async function (dispatch) {
    try {
      const items = await api.fetchDataMonitoreoEvents();
      dispatch(monitoreoFetchAllEvents(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getMonitoreoAllTopics = () => {
  return async function (dispatch) {
    try {
      const items = await api.fetchDataMonitoreoTopics();
      dispatch(monitoreoFetchAllTopics(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const fetchCategoriasDetalle = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchCategoriasDetalle(id);
      dispatch(fetchCategoriasDetalleSuccess(data));
    } catch (error) {
      dispatch(showStatusRegistroManualError());
    }
  };
};

const getMonitoreoByIDRequest = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoByID(id);
      dispatch(fetchMonitoreoIDSuccessAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

/* const getMonitoreoCategoriasFormSelecTicket = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoCategoriasForm();
      dispatch(fetchMonitoreoSuccessCategorias(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
}; */

const getMonitoreoPosts = () => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoCards();
      dispatch(fetchCardsMonitoreoSuccessAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getMonitoreoDesinformacionesDetalle = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoDesinformacionesDetalle(id);
      dispatch(fetchDetalleDesinformacionesSuccess(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getMonitoreoSearch = (query) => {
  return async function (dispatch) {
    try {
      dispatch(fetchSearchMonitoreoRequestAction());
      const data = await api.fetchDataMonitoreoSearch(query);
      dispatch(fetchCardsMonitoreoSuccessAction(data));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getMonitoreoSearchCategories = (query) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchDataMonitoreoSearchCategories(query);
      dispatch(fetchMonitoreoSuccessCategorias(data));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const postMonitoreoAsociar = (asociar, id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      //const responseData = await api.fetchDataMonitoreoByID(id)
      const items = await api.postMonitoreoAsociar(asociar, id);
      dispatch(postMonitoreoAsociadoStatusSuccess());
      // TODO: getDataCard()
    } catch (error) {
      dispatch(postMonitoreoAsociadoStatusFailure());
    }
  };
};

const postMonitoreoDesasociar = (asociar, id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      //const responseData = await api.fetchDataMonitoreoByID(id)
      const items = await api.postMonitoreoDesasociar(asociar, id);
      resetRegistroStatus();
    } catch (error) {
      dispatch(postMonitoreoAsociadoStatusFailure());
    }
  };
};

const resetRegistroStatus = () => {
  return async function (dispatch) {
    try {
      dispatch(makeresetRegistroStatus());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

/* const getMonitoreoNuevaCategoria = (nuevaCategoria) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchMonitoreoNuevaCategoria(nuevaCategoria);

      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoCategoriasForm();
      dispatch(fetchMonitoreoSuccessCategorias(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};
 */
const postMonitoreoNuevoEvento = (nuevoEvento) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      // Create event
      const data = await api.postMonitoreoNuevoEvento(nuevoEvento);
      dispatch(monitoreoNewEventAction(data));

      // Retrieve updated event list
      const items = await api.fetchDataMonitoreoEvents();
      dispatch(monitoreoFetchAllEvents(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const fetchDesinformacionesID = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchDataMonitoreoTags(id);
      dispatch(fetchMonitoreoSuccessDesinformacionesID(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const resetRegistroStatusManual = () => {
  return async function (dispatch) {
    try {
      dispatch(makeresetRegistroStatusManual());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMonitoreoSearchBuscadorPpal = (query) => {
  return async function (dispatch) {
    try {
      dispatch(fetchSearchMonitoreoRequestAction());
      const data = await api.fetchDataMonitoreoSearchPpal(query);
      dispatch(fetchMonitoreoSuccessAction(data));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getRegistrosPage = (filtros) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.getRegistrosPage(filtros);
      dispatch(fetchGuardadosPageSuccessAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getRelevancia = (relevance, id) => {
  return async function (dispatch) {
    try {
      dispatch(fetchMonitoreoRequestAction());
      const items = await api.fetchgetRelevancia(relevance, id);
      /* dispatch(getMonitoreoRequest({
        
        days: "",
        trending:"",
        registered:"",
        redes:"",
        ordering: "-relevance"
      }
        
      )) */
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getNuevoId = (id) => {
  return async function (dispatch) {
    try {
      dispatch(fetchMonitoreoNuevoIdSuccessAction(id));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getMonitoreoGuardadosSearch = (query) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const data = await api.fetchMonitoreoGuardadosSearch(query);
      dispatch(fetchGuardadosPageSuccessAction(data));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const deleteRegistros = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.getdeleteRegistros(id);
      dispatch(
        getRegistrosPage({
          ordering: "",
          exported: "",
          status: "",
          tags: "",
        })
      );
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const getRegistroID = (id) => {
  return async function (dispatch) {
    try {
      dispatch(fectchRegistroID(id));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const patchEditar = (editar, id) => {
  return async function (dispatch) {
    try {
      const items = await api.patchMonitoreoEditar(editar, id);
    } catch (error) {
      dispatch(postMonitoreoAsociadoStatusFailure());
    }
  };
};

const postTrello = (id) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      await api.postCardTrello(id);
      dispatch(
        getRegistrosPage({
          ordering: "",
          exported: "",
          status: "",
          tags: "",
        })
      );
    } catch (error) {
      dispatch(postMonitoreoAsociadoStatusFailure());
    }
  };
};

const getRegistroIDdata = (params) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction());
      const items = await api.fetchgetRegistroIDdata(params);
      dispatch(fetchGuardadosIDdataSuccessAction(items));
    } catch (error) {
      dispatch(fetchMonitoreoError());
    }
  };
};

const changeView = () => {
  return async function (dispatch) {
    try {
      dispatch(changeExpand());
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getData = (arg) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchSendData(arg);
      if (data !== 200) {
        dispatch(fetchDataSuccessAction("error"));
      } else {
        dispatch(fetchDataSuccessAction("sucess"));
      }
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getUserDataCampanas = () => {
  return async function (dispatch) {
    try {
      const items = await api.fetchDataCampanas();
      dispatch(fetchDataSuccessCampanas(items));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDesgrabadorHistorial = () => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabadorHistorial();
      dispatch(fetchDesgrabadorHistorial(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDesgrabadorHistorialID = (id, lang) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabadorHistorialID(id, lang);
      dispatch(fetchDesgrabadorHistorialID(data));

      // Si el idioma es "es", filtra los textos y llama a getTextosDesgrabador
      if (lang === "es") {
        const textosEnEspañol = data.filter(
          (item) => item.language_code === "es"
        );
        const sentences = textosEnEspañol.map((el) => el.text);

        if (sentences && sentences.length > 0) {
          await dispatch(getTextosDesgrabadorHistorial(sentences));
          console.log("getTextosDesgrabadorHistorial llamado con éxito.");
        } else {
          console.log("No hay sentences válidas para getTextosDesgrabador.");
        }
      }
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDesgrabadorHistorialFicheroID = (id) => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabadorHistorialFicheroID(id);
      dispatch(fetchDesgrabadorHistorialFicheroID(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const getDesgrabadorHistorialFichero = () => {
  return async function (dispatch) {
    try {
      const data = await api.fetchDesgrabadorHistorialFichero();
      dispatch(fetchDesgrabadorHistorialFichero(data));
    } catch (error) {
      dispatch(fetchFailureAction(error));
    }
  };
};

const store = createStore(
  productsReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
export {
  store as default,
  getCardsRequestDefault,
  getFilterRequest,
  getLogin,
  getToken,
  getUrl,
  getDesgrabador,
  getTextos,
  getTextosDesgrabador,
  getDays,
  getQuery,
  getPage,
  getMedia,
  getOrder,
  getCategory,
  getCardStatus,
  getFilterFlag,
  getTrello,
  getQueryName,
  getQueryTitle,
  getItemsMenu,
  getMessagesFilter,
  getMonitoreoRequest,
  getUserData,
  getNewPass,
  getNewDataUser,
  getListData,
  getListInvitation,
  getListInvitationDelete,
  getMediosUserQsch,
  getNewUserActive,
  getCodeNewUser,
  getFirstPass,
  getCodeNewUserFail,
  getCodeFail,
  getResetPassword,
  errorNewPassword,
  errorNewData,
  getResetPasswordComplete,
  errorInvitation,
  getFileUpload,
  errorFile,
  getRedesRequest,
  getMonitoreoUser,
  postMonitoreoUser,
  resetLogin,
  getMenuUsuarios,
  getMessagesUpdate,
  postResolveMessage,
  getTrelloMonitoreo,
  fetchHomePedidos,
  fetchMessagesPedidos,
  postMessages,
  getSimilares,
  getPedidosDelete,
  getMenuApp,

  // new
  getMonitoreoTrendings,
  fetchCategoriasDetalle,
  getMonitoreoByIDRequest,
  //getMonitoreoCategoriasFormSelecTicket,
  getMonitoreoPosts,
  getMonitoreoDesinformacionesDetalle,
  getMonitoreoSearch,
  getMonitoreoSearchCategories,
  postMonitoreoAsociar,
  resetRegistroStatus,
  //getMonitoreoNuevaCategoria,
  fetchDesinformacionesID,
  resetRegistroStatusManual,
  getMonitoreoSearchBuscadorPpal,
  updateMonitoreoCardsAction,
  getRegistrosPage,
  getRelevancia,
  getNuevoId,
  getMonitoreoGuardadosSearch,
  deleteRegistros,
  getRegistroID,
  patchEditar,
  postTrello,
  getRegistroIDdata,
  changeView,
  resetDesgrabador,
  getData,
  getUserDataCampanas,
  monitoreoToggleForm,
  monitoreoSetLoadPublicationCardSelectedID,
  monitoreoCreateNewcard,
  monitoreoCreateNewPublication,
  getDataCard,
  getMonitoreoAllEvents,
  getMonitoreoAllTopics,
  postMonitoreoNuevoEvento,
  postMonitoreoDesasociar,
  monitoreoCreateNewPublicationTwitter,
  getDesgrabadorFile,
  getDesgrabadorHistorial,
  getDesgrabadorHistorialID,
  getDesgrabadorHistorialFichero,
  getDesgrabadorHistorialFicheroID,
  clearLabelDesgrabador,
  getCleanErrorDesgrabador,
};
