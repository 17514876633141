import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StyleCard, StyledColModal } from "./styled";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-modal";
import Container from "react-bootstrap/Container";
import { MdMoreVert, MdClose } from "react-icons/md";
import {
  FaChevronCircleRight,
} from "react-icons/fa";
import { getCardStatus, getTrello } from "src/store";

import { connect } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
};

function Card(props) {
  const [openTrello, setOpenTrello] = useState(false);
  const start_text = props.article.content.slice(0, props.start);
  const claim = props.article.content.slice(props.start, props.end);
  const end_text = props.article.content.slice(
    props.end,
    props.article.content.length - 1
  );

  const [modalIsOpen, setIsOpen] = useState(false);
  const dateFormatted = dayjs(props.date).format("D-MM-YYYY");
  const hourFormated = dayjs(props.date).format("HH:mm");

  const handleConfirmation = (e) => {
    e.preventDefault();
    const ic = e.target.elements[0].value;
    const id = props.id;
    props.getCardStatus(id, ic);
  };

  const handleTrello = (e) => {
    e.preventDefault();
    const id = e.target.elements[0].value;
    props.getTrello(id);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openTrelloCard = () => {
    setOpenTrello(true);
  };

  const closeTrelloCard = () => {
    setOpenTrello(false);
  };

  return (
    <>
      {openTrello ? (
        <StyleCard>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right", marginBottom: "12px" }}
            >
              <MdClose
                size={25}
                style={{ color: "#9DA9BD" }}
                onClick={() => closeTrelloCard()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form onSubmit={handleConfirmation}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  {!props.is_valid ? (
                    <Form.Control as="select" style={{ marginBottom: "10px" }}>
                      <option value="True">Chequeable</option>
                      <option value="False">No chequeable</option>
                    </Form.Control>
                  ) : (
                    <p>
                      Esta frase ya fue validada por un humano como{" "}
                      {props.is_checkable === true
                        ? "chequeable"
                        : "no chequeable"}
                    </p>
                  )}
                  <Row>
                    <Col sm={12} md={12} lg={12} xl={12}>
                      {!props.is_valid ? (
                        <Button
                          type="submit"
                          variant="secondary"
                          size="md"
                          style={{
                            width: "100%",
                            marginBottom: "15px",
                            backgroundColor: "#FF3370",
                            border: "none",
                          }}
                        >
                          CONFIRMAR
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>

              <Form onSubmit={handleTrello}>
                <Form.Group>
                  {!!props.trello.find((cards) => cards.id === props.id) ? (
                    <Button
                      type="submit"
                      value={props.id}
                      variant="secondary"
                      size="md"
                      style={{
                        width: "100%",
                        marginBottom: "15px",
                        backgroundColor: "#252C39",
                        border: "none",
                        color: "#FFF",
                        textAlign: "left",
                      }}
                    >
                      <FaChevronCircleRight
                        style={{ marginRight: "8px", color: "#9DA9BD" }}
                      />
                      TARJETA ENVIADA
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        value={props.id}
                        variant="secondary"
                        size="md"
                        style={{
                          width: "100%",
                          marginBottom: "15px",
                          backgroundColor: "#FFF",
                          border: "none",
                          color: "#000",
                          textAlign: "left",
                        }}
                      >
                        <FaChevronCircleRight
                          style={{ marginRight: "8px", color: "#9DA9BD" }}
                        />
                        ENVIAR A TRELLO
                      </Button>
                      <span style={{ fontSize: "10px" }}>
                        Revisá que esta tarjeta no haya sido enviada
                        anteriormente a Trello.
                      </span>
                    </>
                  )}

                  {props.error_trello ? (
                    <Button
                      type="submit"
                      value={props.id}
                      variant="secondary"
                      size="md"
                      style={{
                        width: "100%",
                        marginBottom: "15px",
                        backgroundColor: "#FFF",
                        border: "none",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      <FaChevronCircleRight
                        style={{ marginRight: "8px", color: "#9DA9BD" }}
                      />
                      ERROR
                    </Button>
                  ) : null}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </StyleCard>
      ) : (
        <StyleCard>
          <Row>
            <Col xs={8} sm={8} md={8} lg={10} xl={10}>
              <p className="src">{props.article.source}</p>
              <p className="date">
                {dateFormatted} {hourFormated}hs
              </p>
            </Col>

            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <MdMoreVert
                size={25}
                style={{ color: "#9DA9BD", cursor: "pointer" }}
                onClick={() => openTrelloCard()}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <p className="frase">"{props.claims}."</p>

              <p className="bajada">
                <a
                  href={props.article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.article.title}.
                </a>
                {props.category === "TW" ? (
                  <span>Cantidad de retwits: {props.viralidad_score}</span>
                ) : null}
                {props.category === "YT" ? (
                  <span>
                    Cantidad de visualizaciones: {props.viralidad_score}
                  </span>
                ) : null}
              </p>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={12} xl={8}>
              <p className="nombre">{props.speaker}</p>
            </Col>
            <Col sm={12} md={12} lg={12} xl={4}>
              <div className="contexto">
                <p onClick={openModal}>Contexto</p>
              </div>
            </Col>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              ariaHideApp={false}
            >
              <Row>
                <Container>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <StyledColModal xs={10} sm={11} md={11} lg={11} xl={11}>
                        <p>
                          <a
                            href={props.article.url}
                            target="_blank"
                            className="link_nota"
                            rel="noopener noreferrer"
                          >
                            {props.article.title}.
                          </a>
                        </p>
                      </StyledColModal>
                      <Col xs={2} sm={1} md={1} lg={1} xl={1}>
                        <MdClose
                          onClick={closeModal}
                          style={{
                            color: "#9DA9BD",
                            fontSize: "20px",
                            marginRight: "5px",
                            verticalAlign: "sub",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div>
                          <p>
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                              }}
                            >
                              {props.article.source}
                            </span>
                            {dateFormatted}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <p
                          style={{
                            height: "500px",
                            overflowY: "auto",
                            textAlign: "justify",
                            paddingRight: "40px",
                          }}
                        >
                          {start_text}
                          <span style={{ backgroundColor: "rgb(239 189 204)" }}>
                            {claim}
                          </span>
                          {end_text}.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Container>
              </Row>
            </Modal>
          </Row>
        </StyleCard>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    login: state.login,
    loading_trello: state.loading_trello,
    error_trello: state.error_trello,
    trello: state.trello,
    category: state.category
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardStatus: (id, ic) => dispatch(getCardStatus(id, ic)),
    getTrello: (id) => dispatch(getTrello(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
